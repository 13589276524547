import React from 'react'
import PropTypes from 'prop-types'
import { Star } from 'components/Icons'
import { StarsWrapper } from './Stars.style'

const generateStar = (amount, color, width, height) => {
  const stars = []

  for (let i = 0; i < amount; i++) {
    stars.push(
      <img width="12" height="12" key={i} src="/svg/star-orange.svg" alt="" />
      // <Star key={i} width={width} height={height} color={color || 'orange'} />
    )
  }

  return stars
}

const Stars = props => {
  return (
    <StarsWrapper>
      {generateStar(props.amount, props.color, props.width, props.height)}
    </StarsWrapper>
  )
}

export default Stars
