import styled from 'styled-components'

export const MetaTable = styled.table`
  /* td:first-child {
    font-weight: bold;
  } */
  tr, td {
    font-size: 14px;
    color: #808080;
  }
  td:nth-child(2),
  td:nth-child(2) span {
    font-family: ${props => props.theme.font.family.bold};
  }
  @media screen and (min-width: 1100px) {

  }
  
  .total-text{
    font-size: 18px;
    font-family: ${props => props.theme.font.family.bold};
    color: black;
  }
  
  .total-number{
    font-size: 26px;
    font-family: ${props => props.theme.font.family.bold};
    color: #EB3E3E;
    font-weight: bolder;
  }
`

export const OrderForm = styled.form`

  button {
    margin-top: 15px;
  }
  
  @media screen and (min-width: 1100px) {
    button {
      display: none;
    }
  }
`

export const OrderLabelWithInput = styled.label`
  display: grid;
  grid-row-gap: 10px;

  span {
    font-size: 11px;
    font-family: ${props => props.theme.font.family.medium};
    color: ${props => props.theme.color.black1};
  }

  input {
    width: 100%;
    border: 1px solid #DFE3E9;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    color: #394455;
    padding: 15px;
  }
  @media screen and (min-width: 1100px) {

  }

`

export const OrderWarning = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 14px;
  
  margin-top: 10px;

  p {
    color: black;
    font-size: 14px;
    a {
      color: ${props=>props.theme.color.red2};
      margin-left: 5px;
    }
  }
  @media screen and (min-width: 1100px) {

  }

`

export const OrderItem = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-column-gap: 15px;
  position: relative;
  padding: 22px 20px 22px 0px;
  align-items: center;
  border-bottom: 1px solid #DFE3E9;
  
  @media screen and (min-width: 1100px) {
    padding: 22px 0px;
    .delete-button {
      top: 50%;
    }
  }

`

export const OrderImage = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  img {
    width: 100%;
    max-height: 108px;
    object-fit: scale-down;
  }
`

export const OrderInfo = styled.div`
  h3 {
    font-family: ${props => props.theme.font.family.medium};
    font-size: 14px;
    margin: 0;
  }
  h5.chosen-attributes {
    font-size: 13px;
    margin: 0;
    font-weight: normal;
  }

  p {
    font-size: 23px;
    font-weight: bold;
    font-family: ${props => props.theme.font.family.medium};
    color: #EB3E3E;
    margin: 11px 0px;
  }
  @media screen and (min-width: 1100px) {
    h3 {
      font-size: 16px;
      margin: 0px;
    }
  }

`

export const OrderQuantity = styled.div`
  display: flex;
  align-items: center;

  input {
    outline: none;
    min-width: unset;
    max-width: 75px !important;
    height: 37px;
    border: 1px solid #EDEDED;
    background: white;
    padding-left: 28px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 5px;
    text-align: center;
    

    &:focus {
      border-color: #EDEDED;
    }
  }
  
  input *{
    transform: translateX(10px);
  }

  button {

  }

  .delete-button {
    width: 37px;
    height: 37px;
    border-radius: 4px;
    border: 1px solid #EDEDED;

    svg {
      transform: translateY(2px) translateX(-0.5px);
    }
  }

  .plus-quantity{
    transform: translateY(2px) translateX(54px);
    position: absolute;
  }
  .minus-quantity {
    transform: translateY(2px) translateX(5px);
    position: absolute;

  }


`

export const PurchaseButton = styled.button`
  padding: 14px 20px;
  height: 65px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ff4b2b, #ff453e, #ff424e, #ff405d, #ff416c);
  color: ${props => props.theme.color.white};
  &[disabled] {
    background-image: unset;
    background-color: gray;
  }
  p{
    font-size: 18px;
    
    font-family: ${props => props.theme.font.family.medium};
  }
  
`


export const Orders = styled.div`
  /* min-height: 200px;
  max-height: 350px;
  overflow: auto; */
`

export const OrderPrice = styled.p`
  span {
    font-size: 22px;
  }
`
