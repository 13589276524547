import styled from 'styled-components'

export const LandingContainer = styled.div`

`

export const AddEstimateContainer = styled.div`
  position: relative;
  height: 359px;
  @media screen and (min-width: 1100px) {
    height: auto;
  }
  
`

export const ProductDescription = styled.div`
  padding: 40px;
  padding-top: 31px;
  box-shadow: 0px 3px 35px rgba(141,162,177,0.11);
  border: 1px solid #F2F4F8;
  border-radius: 15px;
  width: 100%;
  @media screen and (min-width: 1100px) {
    height: auto;
  }
  .general-info {
    font-size: 25px !important;
    font-weight: bold;
    font-family: 'FiraGo-Medium';
    color: #5A6679;
    &:after {
      display: block;
      content: " ";
      height: 3px;
      margin-top: 10px;
      margin-bottom: 10px;
      background: linear-gradient( 90deg, #EB3E3E 33%, #EDEDED 10%);
      border-radius: 2px;
      
      @media screen and (max-width: 1100px) {
        background: linear-gradient( 90deg, #EB3E3E 69%, #EDEDED 10%);
      }
    }
  }
`
