import ReactDOM from 'react-dom'
import React, { useContext, useState, createContext, useEffect } from 'react'
import WishlistService from 'services/WishlistService'

const WishlistContext = createContext('config')

export const WishlistContextProvider = ({ children }) => {
  const [isLoaded, setLoading] = useState(false)
  const [products, setProducts] = useState([])

  const fetchWishlist = () => {
    setLoading(true)
    WishlistService.getWishlist()
      .then(c => {
        ReactDOM.unstable_batchedUpdates(() => {
          setLoading(false)
          if (!Array.isArray(c.products)) return
          setProducts(c.products)
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const addToWishlist = sku => {
    return WishlistService.addToWishlist(sku).then(() => {
      fetchWishlist()
    })
  }

  const removeFromWishlist = sku => {
    return WishlistService.removeFromWishlist(sku).then(() => {
      fetchWishlist()
    })
  }

  useEffect(() => {
    fetchWishlist()
  }, [])

  return (
    <WishlistContext.Provider
      value={{
        loading: !isLoaded,
        products: products,
        addToWishlist: addToWishlist,
        removeFromWishlist: removeFromWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}

const useWishlist = () => useContext(WishlistContext)

export default useWishlist
