import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const EmptyHeart = ({ color, width, height }) => {
  return (
    <Svg stroke={color} style={{ strokeWidth: '2px', fill: 'transparent' }} xmlns='http://www.w3.org/2000/svg' width={width || '21.033'} height={height || '18.524'} viewBox='0 0 21.033 18.524'>
      <path id='Heart' d='M1.5 8.075a5.076 5.076 0 0 1 9.517-2.459 5.076 5.076 0 0 1 9.517 2.459q0 5.5-8.827 11.21a1.269 1.269 0 0 1-1.379 0Q1.5 13.575 1.5 8.075z' transform='translate(-.5 -2)' />
    </Svg>
  )
}

EmptyHeart.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default EmptyHeart
