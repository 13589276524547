import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Input from 'components/Input'
import Modal from 'components/Modal'
import Message from 'components/UI/Message'
import { OrderLabelWithInput } from 'components/Widgets/OrderModal/OrderModal.style'
import React, { useState } from 'react'
import OrdersService from 'services/OrdersService'
import useAuth from 'store/AuthContext'

const OrderSuccessConfirmationModal = ({ show, onNumberConfirm, response }) => {
  const [confirmationError, setConfirmationError] = useState(false)
  const { auth } = useAuth()
  const [showRedirect, setShowRedirect] = useState(auth)

  const confirmFormSubmitted = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    setShowRedirect(false)
    OrdersService.confirmAuthCode(formData)
      .then(() => {
        setShowRedirect(true)
        if (!response.redirect_url) {
          onNumberConfirm()
        } else {
          window.location.href = response.redirect_url
        }
      })
      .catch(() => {
        setConfirmationError(true)
      })
  }

  return (
    <Modal title="Order Successfylly Sent" show={show}>
      <form onSubmit={confirmFormSubmitted} className="flex flex-col">
        <input type="hidden" name="user_id" value={response.user_id} />
        <input type="hidden" name="order_id" value={response.order_id} />
        {!response.redirect_url ? (
          <Message
            type="success"
            message="Order Successfully Sent, We will contact you in 1 hours"
          />
        ) : null}
        <OrderLabelWithInput className="mt-4">
          {!response.redirect_url ? (
            <span>
              Too see your order history please confirm your phone number.{' '}
              <br />
              <br />
              Otherwise Order will be placed as one time order
            </span>
          ) : (
            <span>
              You must confirm phone number to place order by card payment.
              <br />
              <br />
              Please confirm your number by sms code sent to you.
            </span>
          )}
          <Input
            style={{ border: '1px solid #FF416C' }}
            type="number"
            placeholder="Enter sms confirmation code"
            name="confirm_code"
          />
          <ErrorMessage
            show={confirmationError}
            message="Invalid confirmation code"
          />
        </OrderLabelWithInput>
        {response.order_url && !response.redirect_url ? (
          <p
            style={{ fontSize: '11px', marginBottom: '10px' }}
            class="mt-4 order-unique-url"
          >
            Order Link: &nbsp;
            <a target="_blank" className="break-all" href={response.order_url}>
              {window.location.origin}
              {response.order_url}
            </a>
          </p>
        ) : null}
        <div
          style={{ justifyContent: 'space-between' }}
          className={`flex flex-col md:flex-row  mt-10`}
        >
          {response.redirect_url && showRedirect ? (
            <a href={response.redirect_url}>
              <Button type="button" disabled={auth} color="orange">
                Pay
              </Button>
            </a>
          ) : (
            <Button disabled={auth} color="orange">
              Log In
            </Button>
          )}
          {response.invoice_url ? (
            <a target="_blank" href={response.invoice_url}>
              <Button
                style={{
                  backgroundColor: 'white',
                  color: '#5c6772',
                  border: '1px solid #5c6772',
                  width: '100%',
                  backgroundImage: 'none',
                  marginTop: '10px',
                }}
                type="button"
                disabled={auth}
                color="orange"
              >
                Download invoice
              </Button>
            </a>
          ) : null}
        </div>
      </form>
    </Modal>
  )
}

export default OrderSuccessConfirmationModal
