import { Check } from 'components/Icons'
import { Container } from 'layouts/Layout.style'
import React from 'react'
import { OfferContainer, OfferText, WeOffer } from './Offer.style'

const Offer = props => {
  return (
    <OfferContainer className="">
      <Container className="container">
        <OfferText>
          <p>Citrus.ge - Biggest Online shop in georgia.</p>
          <h4>
            <img alt="" src="/img/medal.png" />
            <img alt="" className="line" src="/img/offer-emph.svg" />
            “Up to 500 000 clients”
          </h4>
        </OfferText>
        <WeOffer>
          <h3>We offer:</h3>
          <ul>
            <li>
              <Check color="blue1" width="12" height="9.39" />
              Simplicity
            </li>
            <li>
              <Check color="blue1" width="12" height="9.39" />
              High quality products
            </li>
            <li>
              <Check color="blue1" width="12" height="9.39" />
              Fast Delivery
            </li>
          </ul>
        </WeOffer>
      </Container>
    </OfferContainer>
  )
}

export default Offer
