import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Gift = ({ color, width, height }) => {
  return (
    <Svg
      color={color}
      width={width || '16'}
      height={height || '14'}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          class="st0"
          d="M4.7,15.7c-0.3-0.1-0.3-0.3-0.3-0.6c0-1.8,0-3.6,0-5.4c0-0.1,0-0.2,0.1-0.2c1-0.6,2.1-1.2,3.1-1.8
		C7.7,7.6,7.9,7.6,8,7.5c0,0.1,0,0.2,0,0.2c0,1.6,0,3.1,0,4.7c0,0.2,0,0.3,0.2,0.4c0.2,0.2,0.4,0.2,0.6,0c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.3,0.3,0.4,0.4c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.5,0.1c0.2-0.1,0.3-0.2,0.3-0.4
		c0-0.8,0-1.6,0-2.5c0-0.8,0-1.6,0-2.5c0.1,0,0.1,0,0.2,0c1,0,1.9,0,2.9,0c0.4,0,0.5,0.2,0.5,0.5c0,2.3,0,4.7,0,7
		c0,0.3-0.1,0.5-0.3,0.6C10.9,15.7,7.8,15.7,4.7,15.7z"
        />
        <path
          class="st0"
          d="M1.7,7.1C1.7,6.9,1.9,6.8,2,6.7C2.4,6.5,2.9,6.2,3.3,6c0,0-0.1-0.1-0.1-0.1c-0.8-0.6-1-1.6-0.5-2.4
		c0.5-0.8,1.6-1.1,2.4-0.6c0.4,0.2,0.7,0.5,1.1,0.7c0,0,0.1,0,0.1,0.1c0-0.5,0-0.9,0-1.4c0-0.9,0.5-1.6,1.3-1.9
		C8.7,0.1,9.8,0.9,9.9,2c0,0,0,0.1,0,0.1C10,2.1,10.1,2,10.2,2c0.3-0.2,0.6-0.4,1-0.6c0.3-0.2,0.5-0.1,0.7,0.2
		c0.3,0.5,0.6,1,0.8,1.4c0.2,0.3,0.1,0.5-0.2,0.7c-3,1.7-6,3.4-8.9,5.2C3.5,9,3.4,9,3.3,9.1C3.1,9.2,2.8,9.2,2.7,9
		C2.3,8.4,2,7.8,1.7,7.3C1.7,7.2,1.7,7.1,1.7,7.1z M7.3,3.7c0.5-0.3,0.9-0.5,1.3-0.8C9,2.7,9.1,2.2,8.9,1.8C8.8,1.4,8.4,1.2,8,1.2
		C7.5,1.3,7.2,1.7,7.2,2.1C7.2,2.6,7.2,3.2,7.3,3.7z M5.9,4.5C5.9,4.4,5.9,4.4,5.9,4.5C5.5,4.2,5.1,3.9,4.7,3.6
		C4.5,3.6,4.4,3.5,4.3,3.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.4-0.1,0.8,0.2,1c0.3,0.3,0.7,0.3,1,0.1C5.1,5,5.5,4.7,5.9,4.5
		C5.9,4.5,5.9,4.5,5.9,4.5z"
        />
        <path
          class="st0"
          d="M8.9,7.6c0.3,0,0.6,0,0.9,0c0,1.3,0,2.5,0,3.8c-0.4-0.3-0.5-0.3-0.9,0C8.9,10.1,8.9,8.9,8.9,7.6z"
        />
      </g>
    </Svg>
    /* 
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '14'} viewBox='0 0 16 14'>
      <path id='gift' d='M1,45a1,1,0,0,0,1,1H7V41H1Zm14-9H13.684A2.725,2.725,0,0,0,14,34.75,2.754,2.754,0,0,0,11.25,32c-1.3,0-2.141.666-3.219,2.134C6.953,32.666,6.113,32,4.813,32a2.754,2.754,0,0,0-2.75,2.75A2.687,2.687,0,0,0,2.378,36H1a1,1,0,0,0-1,1v2.5a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V37A1,1,0,0,0,15,36ZM4.809,36a1.25,1.25,0,0,1,0-2.5c.622,0,1.081.1,2.691,2.5Zm6.441,0H8.559c1.606-2.391,2.053-2.5,2.691-2.5a1.25,1.25,0,0,1,0,2.5ZM9,46h5a1,1,0,0,0,1-1V41H9Z' transform='translate(0 -32)' />
    </Svg> */
  )
}
const OldGift = ({ color, width, height }) => {
  return (
    <Svg
      color={color}
      width={width || '16'}
      height={height || '14'}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M13.087 2.72684H12.0679C12.2023 2.51411 12.291 2.27531 12.3163 2.02277C12.3971 1.21566 11.9721 0.492146 11.2484 0.171377C10.5832 -0.123471 9.83574 -0.00293981 9.29748 0.485256L8.01068 1.65147C7.76007 1.37737 7.39982 1.20511 7 1.20511C6.59947 1.20511 6.23864 1.37792 5.98798 1.65286L4.69954 0.485119C4.16041 -0.00307651 3.41321 -0.123143 2.74835 0.171541C2.02489 0.492338 1.59975 1.2161 1.68079 2.02318C1.70614 2.27553 1.79479 2.51422 1.92918 2.72684H0.913035C0.408762 2.72684 0 3.13563 0 3.63988V5.00944C0 5.26155 0.204395 5.46597 0.456531 5.46597H13.5435C13.7956 5.46597 14 5.26158 14 5.00944V3.63988C14 3.13563 13.5912 2.72684 13.087 2.72684ZM5.63043 2.57467V2.72684H3.48277C2.91534 2.72684 2.46876 2.19785 2.61048 1.60706C2.67291 1.34688 2.8607 1.1251 3.1036 1.01283C3.43714 0.858662 3.80661 0.908154 4.08674 1.16171L5.63082 2.56122C5.63073 2.56573 5.63043 2.57016 5.63043 2.57467ZM11.4104 1.90155C11.3764 2.37605 10.9478 2.7269 10.4721 2.7269H8.36957V2.57473C8.36957 2.56934 8.36924 2.56401 8.36918 2.55862C8.71896 2.24154 9.47778 1.55379 9.88933 1.18074C10.1232 0.968748 10.4505 0.869791 10.753 0.960025C11.1872 1.08953 11.4421 1.45872 11.4104 1.90155Z"
          fill="white"
        />
        <path
          d="M0.913086 6.37915V13.0748C0.913086 13.5791 1.32185 13.9878 1.82612 13.9878H6.08699V6.37915H0.913086Z"
          fill="white"
        />
        <path
          d="M7.91309 6.37915V13.9878H12.174C12.6782 13.9878 13.087 13.5791 13.087 13.0748V6.37915H7.91309Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </Svg>
    /* 
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '14'} viewBox='0 0 16 14'>
      <path id='gift' d='M1,45a1,1,0,0,0,1,1H7V41H1Zm14-9H13.684A2.725,2.725,0,0,0,14,34.75,2.754,2.754,0,0,0,11.25,32c-1.3,0-2.141.666-3.219,2.134C6.953,32.666,6.113,32,4.813,32a2.754,2.754,0,0,0-2.75,2.75A2.687,2.687,0,0,0,2.378,36H1a1,1,0,0,0-1,1v2.5a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V37A1,1,0,0,0,15,36ZM4.809,36a1.25,1.25,0,0,1,0-2.5c.622,0,1.081.1,2.691,2.5Zm6.441,0H8.559c1.606-2.391,2.053-2.5,2.691-2.5a1.25,1.25,0,0,1,0,2.5ZM9,46h5a1,1,0,0,0,1-1V41H9Z' transform='translate(0 -32)' />
    </Svg> */
  )
}

Gift.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Gift
