import React from 'react'
import {
  BlueButton,
  CustomButton,
  FacebookButton,
  GoogleButton,
  GreyButton,
  LightBlueButton,
  OrangeButton,
  RoundedGreyButton,
  SmallOrangeButton,
  WhiteButton,
  TransparentButton, HexColorButton, SearchButton
} from './Button.style'

const Button = props => {
  let Component = null
  let className = ''
  const { color, ...rest } = props
  switch (color) {
    case 'orange':
      Component = OrangeButton
      className = 'orange-button'
      break
    case 'small-orange':
      Component = SmallOrangeButton
      className = 'small-orange-button'
      break
    case 'search':
      Component = SearchButton
      className = 'small-orange-button'
      break
    case 'lightBlue':
      Component = LightBlueButton
      className = 'lightBlue-button'
      break
    case 'blue':
      Component = BlueButton
      className = 'blue-button'
      break
    case 'grey':
      Component = GreyButton
      className = 'grey-button'
      break
    case 'rounded-grey':
      Component = RoundedGreyButton
      className = 'rounded-grey-button'
      break
    case 'white':
      Component = WhiteButton
      className = 'white-button'
      break
    case 'transparent':
      Component = TransparentButton
      className = 'transparent-button'
      break
    case 'facebook':
      Component = FacebookButton
      className = 'facebook-button'
      break
    case 'google':
      Component = GoogleButton
      className = 'google-button'
      break
    case "hex":
      Component = HexColorButton
      className = 'hex-color-button'
      break
    default:
      Component = CustomButton
      className = 'custom-button'
      break
  }

  return (
    <Component className={`${className} ${props.className || ''}`} {...rest}>
      {props.children}
    </Component>
  )
}
export default Button
