import getProductUrl from 'helpers/getProductUrl'
import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router'
import { onProductViewConversion } from 'services/conversionevents'
import ProductService from 'services/ProductService'
import UtilsService from 'services/UtilsService'
import useCache from './CacheProvider'

const SingleProductContext = createContext()
const installmentPrices = {}

export const SingleProductContextProvider = ({ children }) => {
  const history = useHistory()
  const { getCache } = useCache()
  const [product, setProduct] = useState(getCache('PRODUCT', {}))
  const [variantImage, setVariantImage] = useState(null)
  const [slug, setSlug] = useState(product.slug || '')
  const [variantPath, setVariantPath] = useState('')
  const [landingSignature, setLandingSignature] = useState(undefined)
  const [loading, setLoading] = useState(product.sku === undefined)
  const [productPrice, setProductPrice] = useState(product.price || 0)
  const [installmentPrice, setInstallmentPrice] = useState(
    product.installment_price || 0
  )
  const [chosenVariants, setChosenVariants] = useState({})
  const [urlParsedVariant, setUrlParsedVariant] = useState({})

  useEffect(() => {
    const onProduct = pr => {
      ReactDOM.unstable_batchedUpdates(() => {
        setProduct(pr)
        setProductPrice(pr.price)
        setInstallmentPrice(pr.installment_price)
        setLoading(false)
        installmentPrices[pr.price] = pr.installment_price
        ;(async () => {
          onProductViewConversion(pr, {
            pagetype: landingSignature ? 'landing' : 'product',
          })
        })()
      })
    }

    if (landingSignature) {
      ProductService.getByLandingSignature(landingSignature)
        .then(onProduct)
        .catch(() => setLoading(false))
    } else if (slug) {
      ProductService.getBySlug(slug)
        .then(onProduct)
        .catch(() => setLoading(false))
    }

    return () => {
      setSlug(null)
      setLandingSignature(null)
    }
  }, [slug, landingSignature])

  useEffect(() => {
    if (!product.sku) return
    const parseds = parseVariantPath(variantPath)
    if (parseds !== urlParsedVariant) {
      ReactDOM.unstable_batchedUpdates(() => {
        setUrlParsedVariant(parseds)
        const chosens = getSelectedVariantsFromPath(product.variants, parseds)
        setChosenVariants(chosens)
      })
    }
  }, [variantPath, product.sku])

  // @todo make wi sth reduce
  useEffect(() => {
    if (
      !product.sku ||
      !product.variants?.length ||
      !Object.keys(chosenVariants).length
    )
      return
    const chosenLatinVariants = {
      ...urlParsedVariant,
    }

    let chosen_string = ''

    Object.keys(chosenVariants).forEach(key => {
      if (!chosenVariants[key].url_name) return
      chosenLatinVariants[chosenVariants[key].url_name] =
        chosenVariants[key].url_value
    })
    Object.keys(chosenLatinVariants).forEach((key, ind) => {
      chosen_string += `${ind === 0 ? '' : '_'}${key}--${
        chosenLatinVariants[key]
      }`
    })
    if (product.sku && variantPath !== chosen_string) {
      history.push(getProductUrl(`${product.slug}/${chosen_string}`))
      // setVariantByPath()
    }
  }, [chosenVariants])

  const setUrlParams = p => {
    ReactDOM.unstable_batchedUpdates(() => {
      p.slug && setSlug(p.slug)
      p.path && setVariantPath(p.path)
    })
  }

  const variantChosen = (variant, val) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setChosenVariants(old => {
        if (
          old[variant.attribute_id] &&
          old[variant.attribute_id].id === val.id
        ) {
          delete old[variant.attribute_id]
          return { ...old }
        }

        const newObj = {
          id: val.id,
          value: val.value,
          value_id: val.id,
          name: variant.name,
          url_value: val.url_value,
          image: val.image,
          url_name: variant.url_name,
        }

        old[variant.attribute_id] = newObj
        return { ...old }
      })
      if (
        chosenVariants[variant.attribute_id] &&
        val.price &&
        val.price !== product
      ) {
        setProductPrice(val.price)
        if (installmentPrices[val.price]) {
          setInstallmentPrice(installmentPrices[val.price])
        } else {
          UtilsService.getInstallmentPriceFor(val.price).then(data => {
            installmentPrices[val.price] = data.price
            setInstallmentPrice(data.price)
          })
        }
      }
    })
  }

  useEffect(() => {
    if (typeof chosenVariants !== 'object') return
    let variantWithImage = Object.values(chosenVariants).find(
      v => v.image !== null
    )
    let image = null
    if (variantWithImage) {
      image = variantWithImage.image
    }
    if (image !== variantImage) {
      setVariantImage(image)
    }
  }, [chosenVariants])

  const getProductInfoForFBEvent = () => {
    return {
      content_name: product.name,
      content_category:
        product.breadcrumb?.[product.breadcrumb.length - 1].name,
      content_ids: [product.sku],
      content_type: 'product',
      value: product.price,
      currency: 'GEL',
    }
  }

  return (
    <SingleProductContext.Provider
      value={{
        loading: loading,
        landingSignature: landingSignature,
        setLandingSignature: setLandingSignature,
        getProductInfoForFBEvent: getProductInfoForFBEvent,
        product: product,
        slug: slug,
        images: variantImage
          ? [variantImage, ...product.images]
          : product.images,
        variantImage: variantImage,
        chosenVariants,
        urlParsedVariant,
        productPrice,
        installmentPrice,
        setProductPrice,
        variantChosen,
        setUrlParams,
        inStock: product.stock_status !== 0,
      }}
    >
      {children}
    </SingleProductContext.Provider>
  )
}

const useProduct = () => useContext(SingleProductContext)

const parseVariantPath = variantPath => {
  const parseds = {}
  if (!variantPath) return parseds
  const variants = variantPath.split('_')
  variants.forEach(variant => {
    const [key, value] = variant.split('--')
    if (!key || !value) return
    parseds[key] = value
  })
  return parseds
}

const getSelectedVariantsFromPath = (variants, parsedPath) => {
  const selected = {}
  if (!Array.isArray(variants)) return selected

  variants.forEach(variant => {
    if (!parsedPath[variant.url_name] || !variant.values) return
    const val = variant.values.find(
      val => val.url_value === parsedPath[variant.url_name]
    )
    if (!val) return
    selected[variant.attribute_id] = {
      name: variant.name,
      ...val,
    }
  })

  return selected
}

export default useProduct
