import { Burger } from 'components/Icons'
import React, { useEffect, useState } from 'react'
import useConfig from 'store/ConfigProvider'
import { Cart, Wishlist } from './components/CartAndWishlist'
import PhoneBox from './components/PhoneBox'
import ProfileBox from './components/ProfileBox'
import SearchBox from './components/SearchBox'
import Categories from 'components/Widgets/Categories'
import * as S from './Header.style'
import { MobileAndTabletView } from '../DeviceDetect'
import { Link } from 'react-router-dom'

const Header = () => {
  const { settings, categories } = useConfig()
  const [chatOpened, setChatOpened] = useState(false)

  useEffect(() => {
    if (!Array.isArray(window.$crisp)) window.$crisp = []
    window.$crisp.push([
      'on',
      'chat:opened',
      () => {
        setChatOpened(true)
      },
    ])
    window.$crisp.push([
      'on',
      'chat:closed',
      () => {
        setChatOpened(false)
      },
    ])
  }, [])

  return (
    <div className="header" style={{ display: chatOpened ? 'none' : 'block' }}>
      <div className="header-top">
        <MobileAndTabletView>
          <Categories categories={categories} mobileTop={true} />
        </MobileAndTabletView>
        <Link className="logo" to="/">
          <img src="/img/logo.png" alt="logo" />
        </Link>
        <SearchBox type="desktop" />
        <div className="header-info">
          <PhoneBox number={settings.phone_number} />
          <Cart />
          <ProfileBox />
          {/*<Wishlist />*/}
        </div>
      </div>
      <SearchBox type="mobile" />
    </div>
  )
}

export default Header
