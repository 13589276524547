import axios from 'axios'
import { signatureKey, tokenKey } from 'consts'

class Api {
  constructor() {
    var APIURL = 'https://www.citrus.ge/api/'
    // if (window.location.host == 'localhost:3000') {
    //   var APIURL = 'http://127.0.0.1:8000/api/'
    // } else {
    //   var APIURL = 'https://www.citrus.ge/api/'
    // }

    this.api = axios.create({
      baseURL: APIURL,
      timeout: 10000,
      withCredentials: true,
      params: {
        cache: Math.random(),
        eng: true,
      },
    })
    this.api.defaults.headers.common['Cache-Control'] = 'no-cache'
    this.api.defaults.headers.common['Pragma'] = 'no-cache'
    this.api.defaults.headers.common['Expires'] = '0'
    if (typeof window == 'undefined') return
    this.authToken = localStorage.getItem(tokenKey) || null
    this.setSignature()

    this.setToken(this.authToken)
  }

  call = (method, ...params) => {
    return this.api[method](...params)
  }

  getData = res => res.data

  logOut() {
    this.setToken(null)
  }

  setSignature() {
    if (localStorage.getItem(signatureKey)) {
      this.api.defaults.headers.common['CartSignature'] =
        localStorage.getItem(signatureKey)
    } else {
      const signature =
        new Date().getTime() + Math.round(Math.random() * 1000000)
      localStorage.setItem(signatureKey, signature)
      this.api.defaults.headers.common['CartSignature'] = signature
    }
    return this
  }

  setToken(token) {
    if (token) {
      this.authToken = token
      this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem(tokenKey, token)
    } else {
      this.authToken = undefined
      this.api.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem(tokenKey)
    }
    return this
  }
}

export default new Api()
