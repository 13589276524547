import React from 'react'
import * as S from './SuccessMessage.style'

const SuccessMessage = props => {
  const { show, message } = props
  const msg = typeof message === 'array' ? message.join(', ') : message

  if (!show) return null

  return <S.SuccessBlock>{msg}</S.SuccessBlock>
}

export default SuccessMessage
