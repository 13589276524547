import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import UserService from 'services/UserService'

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [auth, setAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loginModalShown, setLoginModalShow] = useState(false)

  const checkUserAdLoginIfAuth = () => {
    return UserService.bootstrap()
      .then(res => {
        ReactDOM.unstable_batchedUpdates(() => {
          setUser(res.user)
          setLoginModalShow(false)
          setAuth(true)
          setLoading(false)
          redirectUserToDetails()
        })
      })
      .catch(() => {
        setLoading(false)
        logOut()
      })
  }

  const redirectUserToDetails = () => {
    if (!user.password_changed || !user.email) {
      // history.push('/account/details')
    }
  }

  const userAuthenticated = () => {
    return checkUserAdLoginIfAuth()
  }

  const editUser = data => {
    return UserService.edit(data).then(res => {
      checkUserAdLoginIfAuth()
    })
  }

  const logOut = () => {
    UserService.logOut()
    ReactDOM.unstable_batchedUpdates(() => {
      setUser({})
      setAuth(false)
    });

  }

  useEffect(() => {
    checkUserAdLoginIfAuth()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loginModalShown: loginModalShown,
        auth: auth,
        user: user,
        loading: loading,
        logOut: logOut,
        editUser: editUser,
        setLoginModalShow: setLoginModalShow,
        userAuthenticated: userAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export default useAuth
