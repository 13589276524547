import React, { useContext, useState, createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

const DeviceDetect = createContext()

export const DeviceDetectProvider = ({ children }) => {
  const [size, setSize] = useState()

  const resize = () => {
    if (typeof window == 'undefined') return
    let type = ''

    if (window.innerWidth > 1100 && window.innerWidth <= 1250) {
      type = 'smallDesktop'
    }else if (window.innerWidth > 1100) {
      type = 'desktop'
    } else if (window.innerWidth <= 1100 && window.innerWidth >= 768) {
      type = 'tablet'
    } else {
      type = 'mobile'
    }

    setSize(type)
  }

  useEffect(() => {
    resize()
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <DeviceDetect.Provider
      value={{
        device: size,
        isBrowser: size === 'desktop' || size === 'smallDesktop',
        isTablet: size ==='tablet',
        isMobileOrTablet: size==='tablet' || size === 'mobile',
        isSmallBrowser: size === 'smallDesktop'
      }}
    >
      {children}
    </DeviceDetect.Provider>
  )
}

const View = props => {
  const { device } = useContext(DeviceDetect)
  if (props.type.includes(device) || typeof window === 'undefined') {
    return <Fragment> {props.children} </Fragment>
  }

  return null
}

export const BrowserView = props => (
  <View type={["smallDesktop", "desktop"]} {...props}>
    {props.children}
  </View>
)

export const MobileView = props => (
  <View type={["mobile"]} {...props}>
    {props.children}
  </View>
)

export const TabletView = props => (
  <View type={["tablet"]} {...props}>
    {props.children}
  </View>
)

export const BrowserAndTabletView = props => (
  <View type={["smallDesktop", "desktop", "tablet"]} {...props}>
    {props.children}
  </View>
)

export const MobileAndTabletView = props => (
  <View type={["mobile", "tablet"]} {...props}>
    {props.children}
  </View>
)

const useDevice = () => useContext(DeviceDetect)

export default useDevice
