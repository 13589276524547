import React from 'react'
import PropTypes from 'prop-types'
import ReactCountDown from 'react-countdown'

import {
  CountDownWrapper,
  CountDownTime,
  SpecOfferCountDown,
  SpecOfferCountDownTime,
  RoundedCountDown,
} from './CountDown.style'

const withZero = number => {
  if (number.toString().length === 1) {
    return `0${number}`
  }
  return number
}

const SpecOfferRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return 'Finished'
  }

  return (
    <SpecOfferCountDown>
      {/*  <SpecOfferCountDownTime>
        <p>{withZero(days)}</p>
        <span>Days</span>
      </SpecOfferCountDownTime> */}
      <SpecOfferCountDownTime>
        <p>{withZero(hours)}</p>
        <span>Hours</span>
      </SpecOfferCountDownTime>
      <SpecOfferCountDownTime>
        <p>{withZero(minutes)}</p>
        <span>Minutes</span>
      </SpecOfferCountDownTime>
      <SpecOfferCountDownTime>
        <p>{withZero(seconds)}</p>
        <span>Seconds</span>
      </SpecOfferCountDownTime>
    </SpecOfferCountDown>
  )
}

const RoundedRenderer = ({
  days,
  minutes,
  seconds,
  hours,
  completed,
  className,
}) => {
  if (completed) {
    return 'Finished'
  }

  return (
    <RoundedCountDown className={className || ''}>
      <i>
        {hours} Hours : {minutes} Minutes : {seconds} Seconds
      </i>
    </RoundedCountDown>
  )
}

const Renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return 'Finished'
  }

  return (
    <CountDownWrapper>
      <CountDownTime>
        <p>{hours}</p>
        <span>Hours</span>
      </CountDownTime>
      <span className="dots">:</span>
      <CountDownTime>
        <p>{minutes}</p>
        <span>Minutes</span>
      </CountDownTime>
      <span className="dots">:</span>
      <CountDownTime>
        <p>{seconds}</p>
        <span>Seconds</span>
      </CountDownTime>
    </CountDownWrapper>
  )
}

export const CountDown = props => {
  const className = props.className

  const renders = {
    landing: SpecOfferRenderer,
    main: Renderer,
    rounded: res => <RoundedRenderer {...res} className={className || ''} />,
  }

  if (!props.date) {
    return null
  }

  return (
    <ReactCountDown
      date={props.date}
      renderer={
        props.type && renders[props.type] ? renders[props.type] : renders.main
      }
    />
  )
}

CountDown.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.string.isRequired,
}

Renderer.propTypes = {
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  completed: PropTypes.bool,
}

RoundedRenderer.propTypes = {
  days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  completed: PropTypes.bool,
  className: PropTypes.string,
}

SpecOfferRenderer.propTypes = {
  days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  completed: PropTypes.bool,
}

export default CountDown
