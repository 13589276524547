import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Search = ({ color, width, height }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8517 15.4898L13.7244 12.3625C14.6722 11.1218 15.192 9.61686 15.1922 8.02883C15.1922 6.11509 14.4468 4.31576 13.0934 2.96256C11.7402 1.60936 9.9411 0.864014 8.02712 0.864014C6.11338 0.864014 4.31406 1.60936 2.96085 2.96256C0.167345 5.7563 0.167345 10.3018 2.96085 13.0951C4.31406 14.4485 6.11338 15.1939 8.02712 15.1939C9.61516 15.1937 11.1201 14.6739 12.3608 13.7261L15.4881 16.8534C15.6762 17.0417 15.9232 17.1359 16.1699 17.1359C16.4166 17.1359 16.6636 17.0417 16.8517 16.8534C17.2283 16.4769 17.2283 15.8662 16.8517 15.4898ZM4.32441 11.7315C2.28284 9.68996 2.28308 6.36793 4.32441 4.32612C5.31342 3.33735 6.62848 2.79259 8.02712 2.79259C9.42599 2.79259 10.7408 3.33735 11.7298 4.32612C12.7188 5.31512 13.2636 6.63019 13.2636 8.02883C13.2636 9.4277 12.7188 10.7425 11.7298 11.7315C10.7408 12.7205 9.42599 13.2653 8.02712 13.2653C6.62848 13.2653 5.31342 12.7205 4.32441 11.7315Z" fill={color}/>
    </svg>

  )
}

Search.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Search
