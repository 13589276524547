import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Basket = ({ color, width, height }) => {
  return (
    <svg  color={color} width={width || "25"} height={height||'25'} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.03906 21.9895H16.0269C18.1309 21.9895 19.8425 20.2779 19.8425 18.1739V6.76785C19.8425 6.39577 19.5381 6.09133 19.166 6.09133H15.1069V5.07656C15.1069 2.27916 12.8304 0.00268555 10.033 0.00268555C7.2356 0.00268555 4.95913 2.27916 4.95913 5.07656V6.09133H0.900027C0.527943 6.09133 0.223511 6.39577 0.223511 6.76785V18.1739C0.223511 20.2779 1.9351 21.9895 4.03906 21.9895ZM6.31216 5.07656C6.31216 3.02333 7.97977 1.35572 10.033 1.35572C12.0862 1.35572 13.7538 3.02333 13.7538 5.07656V6.09133H6.31216V5.07656ZM1.57654 7.44437H4.95913V9.13566C4.95913 9.50774 5.26356 9.81218 5.63564 9.81218C6.00773 9.81218 6.31216 9.50774 6.31216 9.13566V7.44437H13.7538V9.13566C13.7538 9.50774 14.0583 9.81218 14.4304 9.81218C14.8024 9.81218 15.1069 9.50774 15.1069 9.13566V7.44437H18.4895V18.1739C18.4895 19.5303 17.3834 20.6364 16.0269 20.6364H4.03906C2.68265 20.6364 1.57654 19.5303 1.57654 18.1739V7.44437Z" fill={color ||"#5a6679"}/>
    </svg>

  )
};

Basket.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Basket
