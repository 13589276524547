import React from 'react'
import { BrowserView } from 'components/DeviceDetect'
import * as S from 'components/Header/Header.style'
import translate from 'helpers/translate'
import { User } from 'components/Icons'
import useAuth from 'store/AuthContext'

export default function ProfileBox(props) {
  const { setLoginModalShow, auth, user } = useAuth()

  const HeaderAccountComp = auth ? S.HeaderAccount : S.HeaderAccountButton

  const handleClick = () => {
    if (auth) return
    setLoginModalShow(true)
  }

  return (
    <HeaderAccountComp
      className="flex"
      to="/account/orders"
      onClick={handleClick}
    >
      <User color={auth ? 'orange' : 'black4'} />
      <BrowserView>
        <div className="desktopUserBox">
          <User color={auth ? 'orange' : 'black4'} type="desktop" />
          <p>{auth ? user.name : 'Profile'}</p>
        </div>
      </BrowserView>
    </HeaderAccountComp>
  )
}
