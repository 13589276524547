import styled from 'styled-components'

export const EstimateContainer = styled.div`
  padding: 15px;
  background-color: #F2F4F8;

  @media screen and (min-width: 1100px) {
    padding-top: 48px;
    padding-bottom: 48px;
    button.add-estimate {
      display: none;
    }
  }

  @media screen and (max-width: 680px) {
    .modal-content {
      height: 100vh;
      top: 0px;
    }
  }

`

export const EstimateHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  h3 {
    font-size:  26px;
    font-family: ${props => props.theme.font.family.bold};
    color: black;
    margin: 0px;
  }

  .stars {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 21px;
    align-items: center;

    svg {
      width: 11px;
    }

    span {
      font-size: 14px;
      color: ${props => props.theme.color.black2};
    }
  }
  @media screen and (min-width: 1100px) {
    .stars {
      svg {
        width: auto;
      }
    }
    span {
      font-size: 25px;
      color: ${props => props.theme.color.black2};
    }
  }


`

export const EstimatePercents = styled.div`
  display: grid;
  grid-row-gap: 8px;
  margin-top: 30px;
  @media screen and (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 29px;
    grid-row-gap: 0px;
  }

`

export const PercentsItem = styled.div`
  width: 100%;
  padding: 15px 16px 10px 16px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #DFE3E9;
  box-sizing: border-box;
  justify-content: space-between;
  display: grid;
  grid-row-gap: 8px;
  grid-template-areas: "rating percent"
                      "progress progress";

  p {
    grid-area: rating;
    font-size: 14px;
    color: #1A2738;
    font-family: ${props => props.theme.font.family.medium};

  }

  p.percent {
    color: #5A6679;
    grid-area: percent;
  }

  

  .progress-line {
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: #DFE3E9;
    grid-area: progress;

    &::before {
      content: '';
      display: block;
      width: ${props => props.width || 0}%;
      height: 100%;
      border-radius: 3px;
      background-color: #FFA502;
    }
  }
  @media screen and (min-width: 1100px) {
    
  }

`

export const CommentContainer = styled.div`
  width: 100%;
  border: 1px solid #DFE3E9;
  border-radius: 3px;
  padding: 0px 32px;
  padding-bottom: 42px;
  background-color: white;
  margin-top: 29px;
  box-sizing: border-box;
  @media screen and (min-width: 1100px) {
    
  }

`

export const CommentsHead = styled.div`
  display: grid;
  grid-column-gap: 14px;
  grid-template-columns: 55px auto;
  grid-template-rows: 25px auto;
  grid-template-areas: "avatar name"
                      "avatar time"
                      "avatar stars";

  img {
    border-radius: 50%;
  }
  h2 {
    grid-area: name;
    font-size: 14px;
    font-family: ${props => props.theme.font.family.medium};
    color: #1A2738;
    margin: 0px;
  }

  p {
    font-size: 14px;
    color: #5A6679;
    grid-area: time;
  }

  img {
    grid-area: avatar;
    width: 100%;
  }

  .stars {
    display: flex;
    grid-area: stars;
    align-items: center;
    span {
      font-size: 14px;
      color: #1A2738;
      margin-left: 12px;
    }
  }
  @media screen and (min-width: 1100px) {
    grid-template-columns: 41px auto 1fr;

    .stars {
      grid-area: unset;
    }

    img {
      width: auto;
    }
  }

`

export const CommentText = styled.p`
  font-size: 14px;
  color: #1A2738;
  line-height: 24px;
  margin-top: 17px;
  max-width: 1115px;
  @media screen and (min-width: 1100px) {
    
  }

`

export const CommentPictureSlider = styled.div`
  padding-top: 14px;

  @media screen and (min-width: 1100px) {
  }
`

export const CommentQuestion = styled.div`
  display: grid;
  align-items: center;
  margin-top: 29px;

  p {
    font-size: 14px;
    color: #1A2738;
  }

  

  button {
    border: 1px solid #DFE3E9;
    background-color: white;
    color: #5A6679;
    font-size: 14px;
    border-radius: 4px;
    margin-left: 14px;

    &.active {
      border-color: #FFA701;
      background-color: #FFF8EB;
      color: #FFA701;
    }

  }

  .question-buttons {
    display: flex;
    margin-top: 10px;
    button {
      &:first-child {
        margin-left: 0px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    grid-template-columns: auto auto;
    grid-column-gap: 14px;
  }

`

export const Comments = styled.div`
  padding-bottom: 36px;
  border-bottom: 1px solid #DFE3E9;
  padding-top: 34px;
  margin-bottom: 30px;
  
  @media screen and (min-width: 1100px) {
    margin-bottom: 0px;
  }

`
