import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Burger = ({ color, width, height }) => {
  return (
    <Svg
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '22'}
      height={height || '22.124'}
      viewBox="0 0 22 17.111"
    >
      <g
        id="Vector_Smart_Object"
        data-name="Vector Smart Object"
        transform="translate(-1736 -1076)"
      >
        <g
          id="Vector_Smart_Object-2"
          data-name="Vector Smart Object"
          transform="translate(1736 1076)"
        >
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="22"
            height="4"
            transform="translate(0)"
            fill={color || "#1a2738"}
          />
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="22"
            height="4"
            transform="translate(0 7.391)"
            fill={color || "#1a2738"}
          />
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="22"
            height="4"
            transform="translate(0 14.782)"
            fill={color || "#1a2738"}
          />
        </g>
      </g>
    </Svg>
  )
}

export default Burger
