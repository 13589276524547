import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

import {
  ModalCountainer,
  ModalContent,
  ModalOverlay,
  ModalHeaderContainer,
  ModalBodyContainer,
  ModalFooterContainer,
} from './Modal.style'

import { Close } from 'components/Icons'
import Button from 'components/Button'

export const ModalHeader = props => {
  const { onClose } = props

  return (
    <ModalHeaderContainer className="modal-header">
      <h2>{props.title}</h2>
      {!props.hideClose && (
        <Button onClick={() => onClose()}>
          {props.disableCloseTitle ? '' : 'Close'}
          <Close width="12" height="12" />
        </Button>
      )}
    </ModalHeaderContainer>
  )
}

export const ModalBody = props => {
  return (
    <ModalBodyContainer className={`modal-body ${props.className || ''}`}>
      {props.children}
    </ModalBodyContainer>
  )
}

export const ModalFooter = props => {
  const { children, ...rest } = props
  return <ModalFooterContainer {...rest}>{children}</ModalFooterContainer>
}

export const Modal = props => {
  const { show, onClose, contentClass, bodyClass, ...rest } = props
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    setModalShow(show)

    if (show) {
      document.querySelector('body').style = 'overflow: hidden'
    } else {
      document.querySelector('body').style = 'overflow: hidden auto'
    }
  }, [show])

  const closeModal = () => {
    onClose && onClose()
    document.querySelector('body').style = 'overflow: auto'
    setModalShow(false)
  }

  if (!modalShow) return null

  return (
    <Fragment>
      <ModalOverlay
        display={modalShow ? 'block' : 'none'}
        onClick={closeModal}
        zindex={1501}
      />
      <ModalContent
        zindex={1600}
        className={`modal-content ${contentClass || ''}`}
        {...rest}
      >
        <ModalHeader
          title={props.title}
          disableCloseTitle={props.disableCloseTitle}
          hideClose={props.hideClose}
          onClose={closeModal}
        />
        <ModalBody className={bodyClass || ''}>{props.children}</ModalBody>
      </ModalContent>
    </Fragment>
  )
}

export default Modal
