import 'assets/style/index.scss'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { Footer as LaindingFooter } from 'components/Widgets/Landing'
import LoginModal from 'components/Widgets/LoginModal'
import React, { Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './Global.style'
import {
  Container,
  Content,
  FooterContainer,
  HeaderContainer,
} from './Layout.style'
import { whiteOrange } from './theme'
import OrderModal from '../components/Widgets/OrderModal'
import Loader from 'components/Loader'

const Layout = props => {
  const { layoutType } = props

  return (
    <ThemeProvider theme={whiteOrange}>
      <GlobalStyle />
      {layoutType !== 'landing' ? (
        <div className="app-main-container">
          <HeaderContainer>
            <OrderModal />
            <Container>
              <Header config={{}} />
            </Container>
          </HeaderContainer>
          <Content>
            {layoutType === 'withoutContainer' ? (
              <Suspense
                fallback={<Loader notFull visible={true} type="ThreeDots" />}
              >
                {props.children}
              </Suspense>
            ) : (
              <Container id="content-container">
                <Suspense
                  fallback={<Loader notFull visible={true} type="ThreeDots" />}
                >
                  {props.children}
                </Suspense>
              </Container>
            )}
          </Content>
          <FooterContainer>
            <Container>
              <Footer />
            </Container>
          </FooterContainer>
          <LoginModal />
        </div>
      ) : (
        <Suspense fallback={<Loader notFull visible={true} type="ThreeDots" />}>
          {props.children}
          <LaindingFooter />
        </Suspense>
      )}
    </ThemeProvider>
  )
}

export default Layout
