import useAuth from 'store/AuthContext'

const useIfCanSendOrder = () => {
  return true
  const { auth, user } = useAuth()

  if (auth) {
    return true
  }

  if (user?.number_confirmed) {
    return true
  }

  if (!localStorage.ord) {
    localStorage.setItem('ord', 0)
    return true
  }

  if (localStorage.ord < 2) {
    return true
  }

  if (localStorage.ord > 5) {
    return false
  }

  return false
}

export const incrementOrder = () => {
  localStorage.ord++
}

export default useIfCanSendOrder
