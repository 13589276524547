import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Download = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '14.091'} height={height || '14.088'} viewBox='0 0 14.091 14.088'>
      <g transform='translate(0 0)'>
        <path data-name='Path 2103' d='M13.022,4.791a3.918,3.918,0,0,0,.066-.714A4.026,4.026,0,0,0,5.277,2.71a2.013,2.013,0,0,0-2.7,1.4,3.02,3.02,0,0,0,.448,6.007H5.539a.5.5,0,0,0,0-1.007H3.022a2.013,2.013,0,0,1,0-4.027.5.5,0,0,0,.5-.5,1.007,1.007,0,0,1,1.711-.717.5.5,0,0,0,.85-.278,3.017,3.017,0,0,1,5.952.989q-.024.147-.063.291a.5.5,0,0,0,.208.554,2.013,2.013,0,0,1-1.108,3.691H9.565a.5.5,0,1,0,0,1.007h1.51a3.02,3.02,0,0,0,1.947-5.326Z' transform='translate(-0.001 -0.056)' fill='#868686' />
      </g>
      <g transform='translate(4.538 4.525)'>
        <path data-name='Path 2104' d='M159.74,159.678a.5.5,0,0,0-.7,0l-1.658,1.657v-7.341a.5.5,0,1,0-1.007,0v7.341l-1.657-1.657a.5.5,0,0,0-.712.712l2.517,2.517a.5.5,0,0,0,.712,0h0l2.517-2.517A.5.5,0,0,0,159.74,159.678Z' transform='translate(-153.865 -153.491)' fill='#868686' />
      </g>
    </Svg>
  )
}

Download.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Download
