import calculatePercent from 'helpers/calculatePercent'
import React, { useEffect, useState } from 'react'
import {
  RangeInput,
  RangeSlider,
  RangeTrack,
  RangeTrackActive,
} from './Range.style'

const Range = props => {
  const { from, to, fromValue, toValue } = props
  const [percents, setPercents] = useState([0, 0])

  const calculateFromToPercents = () => {
    const diff = to - from
    const fromValueDiff = fromValue - from
    const toValueDiff = to - toValue
    return [
      calculatePercent(fromValueDiff, diff),
      calculatePercent(toValueDiff, diff),
    ]
  }

  useEffect(() => {
    setPercents(calculateFromToPercents())
  }, [from, to, fromValue, toValue])

  const width = 100 - percents[1] - percents[0]
  const left = percents[0]

  return (
    <RangeSlider>
      <RangeTrack />
      <RangeTrackActive
        style={{
          width: `${width > 100 ? 100 : width}%`,
          left: `${left < 0 ? 0 : left}%`,
        }}
      />
      <RangeInput
        type="range"
        min={from}
        max={to}
        step="1"
        value={fromValue}
        onChange={e => {
          props.onFromChange(e.target.value)
        }}
      />
      <RangeInput
        type="range"
        step="1"
        min={from}
        max={to}
        value={toValue}
        onChange={e => {
          props.onToChange(e.target.value)
        }}
      />
    </RangeSlider>
  )
}

export default Range
