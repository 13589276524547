import Button from 'components/Button'
import Modal from 'components/Modal'
import When from 'components/When'
import React, { useState } from 'react'
import useAuth from 'store/AuthContext'
import LoginForm from './components/LoginForm'
import RegisterForm from './components/RegisterForm'
import * as S from './LoginModal.style'

const LoginModal = props => {
  const [formType, setFormType] = useState('login')
  const { loginModalShown, setLoginModalShow } = useAuth()

  const title = formType == 'login' ? 'Log In' : 'Register'

  return (
    <S.CustomLoginModal>
      <Modal
        show={loginModalShown}
        title={title}
        disableCloseTitle
        onClose={() => setLoginModalShow(false)}
      >
        <S.LoginModalWrapper>
          <When condition={formType === 'login'}>
            <LoginForm />
          </When>
          <When condition={formType === 'register'}>
            <RegisterForm onLogin={() => setFormType('login')} />
          </When>
        </S.LoginModalWrapper>

        <When condition={formType === 'login'}>
          <S.RegisterButton>
            <Button color="lightBlue" onClick={() => setFormType('register')}>
              Register
            </Button>
          </S.RegisterButton>
        </When>

        {/* <S.LoginModalSocial>
          <FacebookLoginButton />
          <GoogleLoginButton />
        </S.LoginModalSocial> */}
      </Modal>
    </S.CustomLoginModal>
  )
}

export default LoginModal
